import axios from 'axios';
import config from "../lib/config.js";
// import 
import { Encryptdata , Decryptdata} from '../lib/encode_decode.js';

export const loginAdmin = async(payload)=>{
    console.log("adminlogin ",payload,config.AdminAPI)
    let data = {"data":Encryptdata(payload)}
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/adminlogin`,
            'data' : data 
            
        })
        // resp.data = await Decryptdata(resp?.data)
        console.log("returned response",resp.data)

        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }


}
export const getarticle=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/getarticle`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}

